import React from 'react';
export const Nav31DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://7164-qdpay-5gwpanamfebf8ba3-1306398229.tcb.qcloud.la/teem/1.svg',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '#Banner5_0',
          children: [
            {
              children: (
                <span>
                  <p>介绍</p>
                </span>
              ),
              name: 'text',
            },
          ],
          className: 'l2io1wfup3-editor_css',
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '#Feature4_0',
          children: [
            {
              children: (
                <span>
                  <p>特点</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '#Feature2_0',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>体验</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '#Footer1_0',
          children: [
            {
              children: (
                <span>
                  <p>关于</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
    subItem: {},
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper l2ionz7czg-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Teem</p>
          </span>
        ),
        className: 'banner5-title l2inb13r3ac-editor_css',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <p>超简单的陌生人社交破冰工具</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                    Teem 是一款社交工具类产品，和现有社交产品不同在于
                              Teem 没有聊天框，没有好友列表，也没有好友圈。Teem
                              通过提供社交场景将不可控的&nbsp;<u>聊天破冰</u>{' '}
                              转变为简单的选择题，双方只需对本次社交场景的多个参与条件进行确认即可，最快
                              5 分钟内完成一次见面社交。<br />
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#Feature2_0',
          className: 'banner5-button l2incomvjf-editor_css',
          type: 'primary',
          children: (
            <span>
              <span>
                <span>
                  <p>立即体验</p>
                </span>
              </span>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image l2hj2g92u1t-editor_css',
    children:
      'https://7164-qdpay-5gwpanamfebf8ba3-1306398229.tcb.qcloud.la/teem/snap2.PNG',
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: {
    className: 'home-page content6 l2iknu6x9g-editor_css',
    appear: true,
    always: true,
    playScale: [0.5, 0.5],
  },
  textWrapper: {
    className: 'content6-text l2ikqofid26-editor_css',
    xs: 24,
    md: 10,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Teem 特点</p>
          </span>
        ),
        className: 'title-h1 l2inhrfvsm8-editor_css',
      },
      {
        name: 'content',
        className: 'title-content l2inio6vjzu-editor_css',
        children: (
          <span>
            <span>
              <p>安全、高效、简单</p>
            </span>
          </span>
        ),
      },
    ],
  },
  img: {
    children:
      'https://7164-qdpay-5gwpanamfebf8ba3-1306398229.tcb.qcloud.la/teem/snap1.PNG',
    className: 'content6-img l2hk66auqxh-editor_css',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title l2iniygyf0r-editor_css',
          children: (
            <span>
              <p>安全</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content l2injeznc0j-editor_css',
          children: (
            <span>
              <p>
              安全永远是第一位的，Teem
                  官方提供中立公开的社交地点，不用担心酒托、饭托等恶意行为。
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: {
          className: 'content6-title l2inj5o09pb-editor_css',
          children: (
            <span>
              <span>
                <p>高效</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
              能见面社交的都是 <u>真</u>{' '}
                  社交，网络水一周不如见面半小时。Teem
                  通过社交场景高效匹配，最快 5 分钟确定一次见面社交。
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: {
          className: 'content6-title l2inj96gzxs-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <p>简单</p>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
              <u>简单</u> 一直是 Teem
                  追求的产品素质。在这里没有花哨的营销文案，没有开通 VIP
                  才能体验的高级功能。甚至 Teem
                  希望用户更快的脱离产品本身，而是花更多的时间去真正的体验真人社交。
              </p>
            </span>
          ),
        },
      },
    ],
  },
};
export const Feature20DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper l2ikwa3qcd7-editor_css',
  },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://7164-qdpay-5gwpanamfebf8ba3-1306398229.tcb.qcloud.la/teem/qrcode-tuya.jpg',
  },
  textWrapper: {
    className: 'content2-text l2ikwqo4tvh-editor_css',
    md: 14,
    xs: 24,
  },
  title: {
    className: 'content2-title l2ini6duwmt-editor_css',
    children: (
      <span>
        <p>微信扫一扫立即体验</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content l2ink5chepo-editor_css',
    children: (
      <span>
        <span>
          <span>
            <p>
            Teem 基于微信生态开发的小程序，无需下载 App
                即可扫码体验，遵循即用即离的产品原则。
            </p>
          </span>
        </span>
      </span>
    ),
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://7164-qdpay-5gwpanamfebf8ba3-1306398229.tcb.qcloud.la/teem/3.svg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>超简单的陌生人社交破冰工具</p>
                </span>
              ),
              className: 'l2iocixbtw-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品' },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: 'https://teem.cool',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Teem - 真的可以见到陌生人</p>
                    </span>
                  </span>
                </span>
              ),
              target: '_blank',
            },
            {
              name: 'link1',
              href: 'https://tinypay.cn',
              children: (
                <span>
                  <span>
                    <span>
                      <p>TinyPay - 支付带动自动化增长</p>
                    </span>
                  </span>
                </span>
              ),
              target: '_blank',
            },
            {
              name: 'link2',
              href: 'https://tinycard.cn',
              children: (
                <span>
                  <p>TinyCard - 链接与成交，为个体赋能</p>
                </span>
              ),
              target: '_blank',
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            {
              href: 'https://qdstorm.com',
              name: 'link0',
              children: (
                <span>
                  <p>奇点风暴</p>
                </span>
              ),
              target: '_blank',
            },
            {
              href: 'mailto:hi@qdstorm.com',
              name: 'link1',
              children: '联系我们',
              target: '_blank',
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>友情链接</p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: 'https://www.bug.pm',
              name: 'link0',
              children: (
                <span>
                  <p>Bug.pm</p>
                </span>
              ),
              target: '_blank',
            },
            {
              href: 'https://github.com/crosslee',
              name: 'link1',
              children: (
                <span>
                  <p>作者 GitHub</p>
                </span>
              ),
              target: '_blank',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          ©2022 by{' '}
          <a href="https://teem.cool" target="_blank" rel="noopener noreferrer">
          苏州奇点风暴信息科技有限公司
          </a>&nbsp;All Rights Reserved &nbsp; <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">苏 ICP 备 2021027628 号 - 3</a>
        </span>
      </span>
    ),
  },
};
